import React, { FC, useMemo, useState } from "react";
import { Switch } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { IBasicProps } from "../IBasicProps";

interface IEmailReminderProps extends IBasicProps {
	reminderLabel: string;
	reminderUnitLabel: string;
	reminderDuration: number;
	reminderEnabled: boolean;
	setReminderEnabled: (enabled: boolean) => void;
	setReminderDuration: (reminderDuration: number) => void;
	disabled?: boolean;
}

export const EmailReminder: FC<IEmailReminderProps> = (props: IEmailReminderProps) => {
	const [isReminderDurationVisible, setIsReminderDurationVisible] = useState(false);
	useMemo(() => {
		setIsReminderDurationVisible(props.reminderEnabled);
	}, [props.reminderEnabled]);
	const toggleReminder = (): void => {
		setIsReminderDurationVisible(!isReminderDurationVisible);
		props.setReminderEnabled(!isReminderDurationVisible);
	};
	return (
		<>
			<div className="switch-button-container">
				<span>{props.t(props.reminderLabel)}</span>
				<Switch
					className="w-4"
					checkedChildren={<CheckOutlined />}
					unCheckedChildren={<CloseOutlined />}
					checked={isReminderDurationVisible}
					onChange={() => toggleReminder()}
					disabled={props.disabled}
				/>
			</div>
			{isReminderDurationVisible && (
				<div className="flex">
					<input
						className="m-0 rounded-none rounded-l-lg"
						type="text"
						name="revocationTime"
						value={props.reminderDuration}
						onChange={e => props.setReminderDuration(Number(e.target.value))}
						disabled={props.disabled}
					/>
					<label className="w-fit px-1 border rounded-r-lg font-semibold bg-gray-300 text-gray-500 flex items-center justify-center">
						<span>{props.t(props.reminderUnitLabel)}</span>
					</label>
				</div>
			)}
		</>
	);
};
