import React, { FC, ReactNode, useEffect, useState } from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import "./ContractList.css";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { useMediaQuery } from "@react-hook/media-query";
import { IBasicProps } from "../IBasicProps";
import { WEB_BASE_URL } from "../constants";
import { Breadcrumbs } from "../breadcrumbs/Breadcrumbs";
import { ICategory } from "../common/model/ICategory";
import { fetchFile } from "../file/downloadFileService";
import { getUserFullName } from "../utils/CustomFunctions";
import { ImageViewer } from "../common/ImageViewer";
import { AccessControl } from "../accessControl/AccessControl";
import { ReactComponent as PlusIcon } from "../images/plus.svg";
import { Report } from "../reports/Reports";
import { getCurrentUser } from "../security/securityService";
import { Loader } from "../common/Loader";
import { notifyInfo } from "../common/notificationService";
import { getAllContracts, getAllContractsToApprove, getSelectedFromParams } from "./contractListService";
import { ContractFilter, resetSelection } from "./ContractFilter";
import { Contract } from "./Contract";
import { IContractListProps } from "./IContractListProps";
import {
	ContractCategoryLabel,
	ContractExpirationLabel,
	ContractIntervalLabel,
	ContractReleaseStateLabel,
	ContractStateLabel,
} from "./ContractLabels";
import { IContractUIProps } from "./IContractUIProps";

const ContractUI: FC<IContractUIProps> = (props: IContractUIProps) => {
	const contract = props.contract;
	const [previewImage, setPreviewImage] = useState<Blob>(new Blob());
	const [loadingImage, setLoadingImage] = useState(false);

	useEffect(() => {
		if (contract.previewImage !== null && contract.previewImage !== undefined) {
			setLoadingImage(true);
			fetchFile(contract.previewImage.id, contract.previewImage.contentType)
				.then(r => setPreviewImage(r))
				.catch(error => console.log(error))
				.finally(() => setLoadingImage(false));
		}
	}, [contract.previewImage]);

	const goto = (contractId: string, affectedUserId: string): void => {
		const userRole = getCurrentUser()?.role;
		switch (userRole) {
			case "financialAdvisor": {
				if (contract.contractState === "NOT_CONFIRMED" && contractId) {
					if (contract.releaseState === "INACTIVE_DEADLINE") {
						props.navigate(`${WEB_BASE_URL}contracts/${contractId}/details`);
					} else {
						props.navigate(`${WEB_BASE_URL}contracts/${contractId}/questionnaire`);
					}
				} else if (contract.contractState === "BEING_REVIEWED") {
					notifyInfo(props.t("contract.under.review"));
				} else {
					props.navigate(`${WEB_BASE_URL}contracts/${contractId}/details`);
				}
				break;
			}

			case "regionalManager": {
				props.navigate(`${WEB_BASE_URL}contracts/${contractId}/questionnaire`);
				break;
			}

			case "superAdmin": {
				if (contract.contractState === "BEING_REVIEWED") {
					props.navigate(`${WEB_BASE_URL}contracts/${contractId}/details/user/${affectedUserId}`);
				} else if (props.openEditPage) {
					props.navigate(`${WEB_BASE_URL}contracts/${contractId}/edit`);
				} else {
					props.navigate(`${WEB_BASE_URL}contracts/${contractId}/details/user/${affectedUserId}`);
				}
				break;
			}

			case "confirmationAdmin": {
				if (contract.contractState === "BEING_REVIEWED") {
					props.navigate(`${WEB_BASE_URL}contracts/${contractId}/details/user/${affectedUserId}`);
				} else if (props.openEditPage) {
					props.navigate(`${WEB_BASE_URL}contracts/${contractId}/questionnaire`);
				} else {
					props.navigate(`${WEB_BASE_URL}contracts/${contractId}/details/user/${affectedUserId}`);
				}
				break;
			}

			case "releaseAdmin": {
				props.navigate(`${WEB_BASE_URL}contracts/${contractId}/edit`);
				break;
			}

			case "editor": {
				props.navigate(`${WEB_BASE_URL}contracts/${contractId}/edit`);
				break;
			}
		}
	};

	return (
		<div className="contract" onClick={() => goto(contract.id, contract.affectedUserId)}>
			<div className="w-32 mr-5 h-32">
				{loadingImage ? (
					<div className="flex items-center justify-center h-full">
						<Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
					</div>
				) : (
					<ImageViewer image={previewImage} />
				)}
			</div>
			<div className="contract-internals">
				<h1>{contract.name}</h1>
				<div className="line-clamp-2" dangerouslySetInnerHTML={{ __html: contract.description }}></div>
				<div className="contract-labels">
					<AccessControl allowedPermission={["superAdmin", "confirmationAdmin"]} {...props}>
						<ContractExpirationLabel {...props} />
					</AccessControl>
					{props.showContractState &&
						(contract.releaseState === "INACTIVE" ? (
							<ContractReleaseStateLabel releaseState={contract.releaseState} {...props} />
						) : (
							<ContractStateLabel
								releaseState={contract.releaseState}
								contractState={contract.contractState}
								{...props}
							/>
						))}
					{props.showReleaseState && <ContractReleaseStateLabel releaseState={contract.releaseState} {...props} />}
					{props.showCategory && (
						<ContractCategoryLabel
							iconName={contract.category.iconName}
							categoryName={contract.category.value}
							iconSize="16"
						/>
					)}
					{props.showApplicationDate && (
						<AccessControl allowedPermission={["editor", "releaseAdmin", "superAdmin"]} {...props}>
							<ContractIntervalLabel {...props} />
						</AccessControl>
					)}
					{props.showUserName && contract.lastEventUser !== null && (
						<AccessControl allowedPermission={["superAdmin", "confirmationAdmin"]} {...props}>
							<span className="contract-label">
								{getUserFullName(props.t("user.id.label"), contract.lastEventUser)}
							</span>
						</AccessControl>
					)}
					{contract.employee !== null && (
						<AccessControl allowedPermission={["financialAdvisor"]} {...props}>
							<span className="contract-label">
								{props.t("contract.list.employee")} &nbsp;
								{contract.employee.firstname} &nbsp;
								{contract.employee.lastname}
							</span>
						</AccessControl>
					)}
					<AccessControl allowedPermission={["financialAdvisor"]} {...props}>
						<ContractExpirationLabel {...props} />
					</AccessControl>
				</div>
			</div>
		</div>
	);
};

interface IContractListUIProps extends IBasicProps {
	contracts: Contract[];
	openEditPage?: boolean;
}

interface IEmptyContractProps extends IBasicProps {
	onEmptyList: () => any;
	showContractsToApprove?: boolean;
}

const EmptyContractListUI: FC<IEmptyContractProps> = ({
	t,
	onEmptyList,
	showContractsToApprove,
}: IEmptyContractProps) => (
	<div className="no-contracts">
		{showContractsToApprove ? (
			<div>{t("contract.list.no.contracts.to.approve")}</div>
		) : (
			<>
				<div>{t("contract.list.no.contracts")}</div>
				<button className="primary h-7 mt-2" onClick={onEmptyList}>
					{t("contract.list.no.contracts.back")}
				</button>
			</>
		)}
	</div>
);

const ContractListUI: FC<IContractListUIProps> = (props: IContractListUIProps) => (
	<ul className="list-none p-0 pb-2">
		{props.contracts.map((c, index) => (
			<li className="p-0" key={index}>
				<ContractUI contract={c} {...props} />
			</li>
		))}
	</ul>
);

export const ContractList: FC<IContractListProps> = ({ searchParameters, showFilterSelect = true, ...props }) => {
	if (!props.authenticated) {
		const parts = window.location.href.split(WEB_BASE_URL);
		return <Navigate to={`${WEB_BASE_URL}sso?redirect=${parts[1]}`} replace={true} />;
	}

	const [contracts, setContracts] = useState<Contract[]>([]);
	const [searchParams, setSearchParams] = useSearchParams();
	const [, setSearch] = useState(searchParameters);
	const [loading, setLoading] = useState(true);
	const isLargeScreen = useMediaQuery("(min-width: 1024px)");

	useEffect(() => {
		setLoading(true);
		if (!props.showContractsToApprove) {
			getAllContracts(searchParams.entries())
				.then(r => setContracts(r as Contract[]))
				.catch(error => console.log(error))
				.finally(() => setLoading(false));
		} else if (props.showContractsToApprove) {
			getAllContractsToApprove()
				.then(r => setContracts(r as Contract[]))
				.catch(error => console.log(error))
				.finally(() => setLoading(false));
		}
	}, [searchParams, props.showContractsToApprove]);

	const applyFilter = (selectedValue: ICategory | null): void => {
		const params: string[][] = [];
		const releaseState = searchParams.get("releasestate");
		if (releaseState !== null) {
			params.push(["releasestate", releaseState]);
		}
		if (selectedValue != null) {
			params.push(["categorykey", selectedValue?.key || ""]);
		}
		setSearch(params);
		setSearchParams(new URLSearchParams(params));
	};
	const resetFilter = (): void => {
		if (resetSelection !== null) {
			resetSelection();
		}
	};

	const renderContractListSize = (index: number, size: number): ReactNode =>
		index === size - 1 ? contracts.length : <></>;

	const gotoCreatContract = (): void => {
		props.navigate(`${WEB_BASE_URL}contracts/create`);
	};

	return (
		<>
			{isLargeScreen ? (
				<div className="contract-list">
					<div className="contract-list-header">
						<div className="flex">
							<Breadcrumbs prefix={renderContractListSize} {...props} />
							{props.showContractCreate && (
								<AccessControl allowedPermission={["superAdmin", "editor"]} {...props}>
									<button
										className="primary flex items-center justify-between lg:h-7 min-h-fit gap-3 text-xs lg:text-sm"
										onClick={() => gotoCreatContract()}
									>
										<span>{props.t("add.contract")}</span> <PlusIcon fill="white" className="w-5 lg:w-3" />
									</button>
								</AccessControl>
							)}
						</div>
						<div className="flex">
							{showFilterSelect && (
								<ContractFilter
									onFilterChange={applyFilter}
									selected={getSelectedFromParams(searchParams.entries())}
									{...props}
								/>
							)}
							{props.showGenerateReport && (
								<AccessControl
									allowedPermission={["superAdmin", "editor", "releaseAdmin", "regionalManager", "confirmationAdmin"]}
									{...props}
								>
									<Report {...props} />
								</AccessControl>
							)}
						</div>
					</div>
					{contracts.length !== 0 && !loading && <ContractListUI contracts={contracts} {...props} />}
					{contracts.length === 0 && !loading && <EmptyContractListUI onEmptyList={resetFilter} {...props} />}
					{loading && <Loader size={72} />}
				</div>
			) : (
				<div className="mx-auto">
					<div className="flex-col">
						<div className="flex-col">
							<div className="flex justify-center mb-2">
								<Breadcrumbs prefix={renderContractListSize} {...props} />
							</div>
							<div className="flex justify-center">
								{props.showContractCreate && (
									<AccessControl allowedPermission={["superAdmin", "editor"]} {...props}>
										<button className="primary-with-icon-tablet" onClick={() => gotoCreatContract()}>
											<PlusIcon fill="white" className="w-5 lg:w-3" />
										</button>
									</AccessControl>
								)}
								<div className="flex">
									{props.showGenerateReport && (
										<AccessControl
											allowedPermission={[
												"superAdmin",
												"editor",
												"releaseAdmin",
												"regionalManager",
												"confirmationAdmin",
											]}
											{...props}
										>
											<Report {...props} />
										</AccessControl>
									)}
									{showFilterSelect && (
										<ContractFilter
											onFilterChange={applyFilter}
											selected={getSelectedFromParams(searchParams.entries())}
											{...props}
										/>
									)}
								</div>
							</div>
						</div>
					</div>
					{contracts.length !== 0 && !loading && <ContractListUI contracts={contracts} {...props} />}
					{contracts.length === 0 && !loading && <EmptyContractListUI onEmptyList={resetFilter} {...props} />}
					{loading && <Loader size={72} />}
				</div>
			)}
		</>
	);
};
