import React, { FC, useEffect, useState } from "react";
import { Checkbox } from "antd";
import { ICheckboxOption, IEmployee } from "../../common/model/IQuestionnaire";
import { getCurrentUser } from "../../security/securityService";
import "./QuestionnaireCheckbox.css";

const columnVariants = {
	1: "grid-cols-1",
	2: "grid-cols-2",
	3: "grid-cols-3",
	4: "grid-cols-4",
	5: "grid-cols-5",
	6: "grid-cols-6",
	7: "grid-cols-7",
	8: "grid-cols-8",
	9: "grid-cols-9",
	10: "grid-cols-10",
	11: "grid-cols-11",
	12: "grid-cols-12",
};

interface IQuestionnaireCheckboxProps {
	initialValue: string;
	label: string;
	name: string;
	options?: ICheckboxOption[];
	fillQuestionnaireValue: (fieldName: string, value: string) => void;
	setFormValid: (valid: boolean) => void;
	previousValue?: string;
	employees?: IEmployee[];
	includePrimaryEmployee: boolean;
}

interface IQuestionnaireEmployeeAnswer {
	userId: string;
	companyId?: string;
	employeeId?: string;
	employeeKey?: string;
	firstname: string;
	lastname: string;
	email?: string;
	role: string;
	answers: IQuestionnaireCheckboxAnswer[];
}

interface IQuestionnaireCheckboxAnswer {
	name: string;
	selected: boolean;
}

export const QuestionnaireEmployeeCheckbox: FC<IQuestionnaireCheckboxProps> = (props: IQuestionnaireCheckboxProps) => {
	const [answers, setAnswers] = useState([] as IQuestionnaireEmployeeAnswer[]);
	const [employees, setEmployees] = useState([] as IEmployee[]);
	const [options, setOptions] = useState([] as ICheckboxOption[]);

	useEffect(() => {
		setEmployees(employeesToDisplay(props.employees || [], props.includePrimaryEmployee));
		setOptions(sortedCheckboxOptions(props.options || []));
	}, []);

	useEffect(() => {
		if (props.previousValue) {
			setAnswers(setPreviousAnswers(JSON.parse(props.previousValue) as IQuestionnaireEmployeeAnswer[]));
		} else {
			setAnswers(initialValues());
		}
	}, [employees, options]);

	const setPreviousAnswers = (previousAnswers: IQuestionnaireEmployeeAnswer[]): IQuestionnaireEmployeeAnswer[] => {
		const notExistingEmployees = employees.filter(
			employee =>
				previousAnswers.filter(previousAnswer => previousAnswer.employeeId === employee.employeeId).length === 0
		);
		if (notExistingEmployees.length !== 0) {
			notExistingEmployees.forEach(notExistingEmployee => {
				previousAnswers.push({
					userId: notExistingEmployee.userId,
					employeeId: notExistingEmployee?.employeeId as string,
					employeeKey: notExistingEmployee?.employeeKey as string,
					companyId: notExistingEmployee?.companyId as string,
					firstname: notExistingEmployee.firstname,
					lastname: notExistingEmployee.lastname,
					email: notExistingEmployee?.email as string,
					role: notExistingEmployee.role,
					answers: options.map(opt => ({
						name: opt.name,
						selected: opt.initialValue,
					})),
				});
			});
		}
		return previousAnswers;
	};

	const employeesToDisplay = (employeeList: IEmployee[], includePrimaryEmployee: boolean): IEmployee[] => {
		const user = getCurrentUser();
		const employeesToDisplayList: IEmployee[] = [];
		if (includePrimaryEmployee) {
			employeesToDisplayList.push({
				userId: user?.id as string,
				firstname: user?.firstname as string,
				lastname: user?.lastname as string,
				role: user?.role as string,
				employeeId: null,
				email: null,
				employeeKey: null,
				companyId: null,
			});
		}
		if (["superAdmin", "releaseAdmin"].includes(user?.role as string) && employeeList.length === 0) {
			employeesToDisplayList.push(
				...[
					{
						userId: "1",
						employeeId: "1",
						firstname: "Mitarbeiter",
						lastname: "1",
						email: "email1",
						companyId: "companyId1",
						role: "role1",
					},
					{
						userId: "2",
						employeeId: "2",
						firstname: "Mitarbeiter",
						lastname: "2",
						email: "email2",
						companyId: "companyId2",
						role: "role2",
					},
					{
						userId: "3",
						employeeId: "3",
						firstname: "Mitarbeiter",
						lastname: "3",
						email: "email3",
						companyId: "companyId3",
						role: "role3",
					},
				]
			);
			return employeesToDisplayList;
		}
		employeesToDisplayList.push(...employeeList);
		return employeesToDisplayList;
	};

	const sortedCheckboxOptions = (checkboxOptions: ICheckboxOption[]): ICheckboxOption[] =>
		checkboxOptions.sort((a, b) => {
			if (a.sortOrder === b.sortOrder) {
				return a.label.localeCompare(b.label);
			} else {
				return a.sortOrder - b.sortOrder;
			}
		});

	useEffect(() => {
		props.setFormValid(answers.filter(ans => ans.answers.filter(opt => opt.selected).length > 0).length > 0);
		props.fillQuestionnaireValue(props.name, JSON.stringify(answers));
	}, [answers]);

	const getValue = (employee: IEmployee, option: ICheckboxOption): boolean => {
		const result = answers
			.find(emp => emp.employeeId === employee.employeeId)
			?.answers.find(ans => ans.name === option.name);
		return result ? result.selected : false;
	};

	const setSelectedValue = (employee: IEmployee, option: ICheckboxOption): void => {
		const currentSelection = !getValue(employee, option);
		const employeeExists = answers.filter(ans => ans.employeeId === employee.employeeId).length > 0;
		if (!employeeExists) {
			const newEmployeeAnswer = {
				userId: employee.userId,
				employeeId: employee?.employeeId as string,
				employeeKey: employee?.employeeKey as string,
				companyId: employee?.companyId as string,
				firstname: employee.firstname,
				lastname: employee.lastname,
				email: employee?.email as string,
				role: employee.role,
				answers: options.map(opt => ({
					name: opt.name,
					selected: opt.name === option.name ? currentSelection : option.initialValue,
				})),
			};
			setAnswers([...answers, newEmployeeAnswer]);
		} else {
			setAnswers(
				answers.map(ans => {
					if (ans.employeeId === employee.employeeId) {
						return filterAnswers(ans, option, currentSelection);
					} else {
						return ans;
					}
				})
			);
		}
	};

	const filterAnswers = (
		ans: IQuestionnaireEmployeeAnswer,
		option: ICheckboxOption,
		currentSelection: boolean
	): IQuestionnaireEmployeeAnswer => {
		const optionExists = ans.answers.filter(opt => opt.name === option.name).length > 0;

		if (!optionExists) {
			const newOptionAnswer = { name: option.name, selected: currentSelection };
			return { ...ans, answers: [...ans.answers, newOptionAnswer] };
		} else {
			const newOptionAnswers = ans.answers.map(opt => {
				if (opt.name === option.name) {
					return { ...opt, selected: currentSelection };
				} else {
					return opt;
				}
			});
			return { ...ans, answers: newOptionAnswers };
		}
	};

	const initialValues = (): IQuestionnaireEmployeeAnswer[] =>
		employees.map(employee => ({
			userId: employee.userId,
			employeeId: employee.employeeId as string,
			employeeKey: employee.employeeKey as string,
			companyId: employee.companyId as string,
			firstname: employee.firstname,
			lastname: employee.lastname,
			email: employee.email as string,
			role: employee.role,
			answers: options.map(opt => ({
				name: opt.name,
				selected: opt.initialValue,
			})),
		}));

	return (
		<div className="flex flex-col items-start">
			<span className="mb-2">{props.label}</span>
			<div className={`grid bg-white divide-y divide-slate-200 ${columnVariants[options.length + 1]}`}>
				<div className="border-t border-slate-200"></div>
				{options.map((option, index) => (
					<div className="py-2 grid justify-center font-extrabold break-all xl:break-normal" key={`opt-${index}`}>
						{option.label}
					</div>
				))}
				{employees.map((employee, empIndex) => (
					<React.Fragment key={`emp-${empIndex}`}>
						<div className="pl-2 grid items-center break-all xl:break-normal">
							{employee.firstname} {employee.lastname}
						</div>
						{options.map((option, index) => (
							<div className="py-2 grid justify-center items-center" key={`emp-${empIndex}-${index}`}>
								<Checkbox
									className="w-auto"
									type="checkbox"
									name={props.name}
									checked={getValue(employee, option)}
									onChange={() => setSelectedValue(employee, option)}
								/>
							</div>
						))}
					</React.Fragment>
				))}
			</div>
		</div>
	);
};
