import React, { FC, useEffect, useState } from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import { IBasicProps } from "../IBasicProps";
import { SSO_URL, WEB_BASE_URL } from "../constants";
import { Loader } from "../common/Loader";
import { get } from "./secureClient";
import { IAuthResponse } from "./securityService";
import { NotAuthView } from "./NotAuthView";
import { saveAuthToken } from "./secureStorage";

interface ISSOProps extends IBasicProps {
	postLoginAction: (a: any) => any;
}

export let authError: string | null;

export const Sso: FC<ISSOProps> = (props: ISSOProps) => {
	if (props.authenticated) {
		return <Navigate to={`${WEB_BASE_URL}`} replace={true} />;
	}
	const [searchParams] = useSearchParams();
	const [code] = useState(searchParams.get("code"));
	const [link] = useState(searchParams.get("viewPath"));

	const login = (): any => (window.location.href = `${SSO_URL}`);

	useEffect(() => {
		if (code !== null) {
			get(`auth/sso?code=${code}`)
				.then(data => {
					const auth = data as IAuthResponse;
					if (auth.accessToken) {
						if (auth.user.role === null) {
							props.navigate(`${WEB_BASE_URL}auth-error`);
							return;
						}
						saveAuthToken(JSON.stringify(data));
					}
					props.postLoginAction(true);
					if (link !== null && link !== "null") {
						props.navigate(`${WEB_BASE_URL}${link}`);
						return;
					}
					props.navigate(WEB_BASE_URL);
				})
				.catch(err => {
					/* eslint-disable */
					authError = err.response.data as string;
					props.navigate(`${WEB_BASE_URL}auth-error`);
					/* eslint-enable */
					return;
				});
		}
	}, []);
	if (code !== null) {
		return <Loader size={96} />;
	}
	return <NotAuthView action={login} showError={false} logoutButtonName={props.t("logout.back")} {...props} />;
};
